const API_PATH = {
	BEES: "/v2/bees",
	SHIPMENTS: "/v2/shipment",
	ASSETS: "/v2/assets",
	V1_ASSETS: "/v1/assets",
	AUTO_CRUD_CUSTOM_FIELDS: "/v2/autocrud/custom_fields",
	FILTER: "/filter",
	SHIPMENT_SUMMARY: "/v2/shipment/summary",
	LOGOUT: "/auth/logout"
};

export { API_PATH };
