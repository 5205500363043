import Tag from "../../Tag";
import { ChevronDown, ChevronUp } from "@carbon/icons-react";

import { Container, IconWrapper, StyledInput, TagWrapper } from "./styles";
import type { DatePickerSelectProps } from "./types";

export default function DatePickerSelect({ className, placeholder, value, onClick, isOpen }: DatePickerSelectProps): JSX.Element {
	const truncateText = (text: string, num: number) => (text.length > num ? `${text.substring(0, num)}...` : text);

	const inputSize = document.getElementsByClassName("inputDate") as HTMLCollectionOf<HTMLElement>;

	const size = (inputSize != null && inputSize?.[0]?.offsetWidth) || 0;

	const valueTruncate = (inputWidth: number) => {
		if (inputWidth) {
			if (inputWidth < 306) {
				if (inputWidth < 200) {
					return truncateText(value as string, 6);
				}
				return truncateText(value as string, 10);
			} else {
				return value;
			}
		}
	};

	return (
		<Container className={className}>
			<StyledInput placeholder={placeholder} value={size ? valueTruncate(size) : undefined} className="inputDate" onClick={onClick} />
			<IconWrapper>{isOpen ? <ChevronUp onClick={onClick} /> : <ChevronDown onClick={onClick} />}</IconWrapper>
		</Container>
	);
}
